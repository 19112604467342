import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box, Badge, Text, Heading, css, IconButton, Input, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import ReactModal from 'react-modal'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { AiOutlineClose, AiOutlineSearch, AiOutlineDelete, AiOutlineCloudDownload, AiOutlineCloudSync, AiOutlineCheck, AiOutlineArrowLeft } from "react-icons/ai";
import noSearchPng from '../../../assets/no-search-die-cutting.png'
import { setKtTokenSifre, logOut, getKtLogin } from '../../../../../../site/src/service/auth'
import { ModalContext } from '@solid-ui-components/Modal'
import loadsGif from '../../../assets/loads.gif'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import indirmeCss from './_indirmelerim.css'
import removeGif from '../../../assets/remove-popup.gif'


const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 280,
    maxWidth: [`none`, null, null, 280],
    minHeight: 325,
    maxHeight: [`none`, null, null, 325],
    cursor: `pointer`,
    p: 4
  },
  card: {

  },
  artic: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },

  indirmeImageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `200px`
  },

  detayimageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `450px`,
    margin: '10px',
    maxWidth: 650
  },

  searchL: {
    flex: [`100%`, null, null, 1],
    minWidth: 400,
  },

  searchR: {
    flex: [`100%`, null, null, 1],
    minWidth: 200,
    maxWidth: [`none`, null, null, 350],
    textAlign: `right`,
    marginTop: '0.5em'
  },
  textInp: {
    flex: [`100%`, null, null, 1],
    height: 50,
    backgroundColor: "white"
  },
  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },

  wrapper2: {
    flex: [`100%`, null, null, 1],
    maxWidth: [`none`, null, null, 675],
    marginTop: "20px",
  },
  wrapper1: {
    flex: [`100%`, null, null, 1],
    maxWidth: [`none`, null, null, 350],
    marginTop: "20px",

  },

}


const BlogBlock05 = ({ ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const { setActiveModal } = useContext(ModalContext)

  const [btnID, setbtnID] = useState("");


  const formId = "frmIndirme"

  const [Data, setData] = useState(Data);
  const [filterData, setFilterData] = useState(Data);
  const [seciliData, setSeciliData] = useState(Data);
  const [silmePopup, setsilmePopup] = useState(false);


  useEffect(async () => {
    if (typeof window !== 'undefined') {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {
        indirmeListDoldur();
      } else {
        logOut();
      }
    }
  }, []);

  const [ldg, setLdg] = useState(false);

  function indirmeListDoldur() {
    // setLdg(false);
    httpServiceToken.post("Locked/IndirmeListem", {}).then((res) => {
      if (res.DogruMu) {
        setData(res.Data);
        setFilterData(res.Data);
        setKtTokenSifre(res.Refresh);
        setLdg(true);
      } else {
        setLdg(true);
      }
    }).catch((err) => {
      setLdg(true);
    });

  }


  const onChanges = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value.replace(",", ".")
      }
    })
  }

  const onSubmit = async e => {
    e.preventDefault();
    const ktLoginMiii = await getKtLogin();
    if (ktLoginMiii == true) {
      if (btnID == "btnSilEvet" && seciliData) {

        httpServiceToken.post("Locked/IndirmeSil", { params: { Id: seciliData.Id } }).then((res) => {
          if (res.DogruMu) {

          } else {

          }
          indirmeListDoldur();
          setdetayModalShow(false);
          setsilmePopup(false);
          setFormValues({
            ...formValues,
            [formId]: {
              ...formValues?.[formId]
            }
          })
          setbtnID("");

        }).catch((err) => {
          setbtnID("");
        });
      }
    }
    else {
      localStorage.setItem("623327ac7dc90a0020f891aa", "4");
      setActiveModal('authentication');
    }
  }


  const [detayModalShow, setdetayModalShow] = useState(false);


  const [aramaMi, setAramaMi] = useState(true);
  const [searchText, setSearchText] = useState("");


  const turkishCharacterRegex = keyword => keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })

  }, [success, submitting, setFormValues])



  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }

    })
    setAramaMi(e.target.value.length == 0)
    setSearchText(e.target.value);

    let value = turkishCharacterRegex(e.target.value);
    let valueUpper = turkishCharacterRegex(e.target.value.toUpperCase());
    let valueLower = turkishCharacterRegex(e.target.value.toLowerCase());

    let result = [];
    result = Data.filter((data) => {
      data.Select = false;
      if (data.ModelAdi == "KT-0001") {
        let a = 0;
      }
      const arama = turkishCharacterRegex((data.IsAdi + " " + data.ModelHastag + " " + data.OtomatikArama).toUpperCase());
      const aramaList = arama.split(" ");
      aramaList.push(arama);

      const valueUPPERList = valueUpper.split(" ");
      valueUPPERList.map((valUPPER) => {
        aramaList.map((arm) => {
          if (data.Select == false)
            data.Select = arm.includes(valUPPER);
        });
      });

      const valueLOWERList = valueLower.split(" ");
      valueLOWERList.map((valLOWER) => {
        aramaList.map((arm) => {
          if (data.Select == false)
            data.Select = arm.includes(valLOWER);
        });
      });

      return data.Select;
    });
    setFilterData(result);
    if (e.target.value.length == 0) {
      setFilterData(Data);
    }
  }

  return (

    <Container>
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <div>
          <Box>
            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, marginLeft: '1.5em', marginRight: '5.5em' }}>
              <Box sx={styles.searchL}>
                <form

                  onSubmit={onSubmit}
                  method='POST'
                >
                  <Box variant='forms.field'>

                    <Input
                      {...props}
                      onChange={onChange}
                      placeholder={"Ara"}
                      name={"txtSearch"}
                      id={"txtSearch"}
                      value={aramaMi == true ? "" : formValues?.[formId]?.["txtSearch"] || undefined}
                      sx={styles.textInp}
                    />
                    <Box sx={{ marginTop: '0.3em', p: 2 }}>
                      {aramaMi == true ?
                        <AiOutlineSearch onClick={e => {
                          setAramaMi(true);
                          setFilterData(Data);
                          setSearchText("");
                        }} size={21} color="grey" /> : <AiOutlineClose
                          onClick={e => {
                            setAramaMi(true);
                            setFilterData(Data);
                          }}
                          size={21} color="grey" />}
                    </Box>
                  </Box>

                </form>
              </Box>
              <Box sx={styles.searchR}>

              </Box>
            </Flex>
          </Box>
          {filterData && (
            <Flex sx={{ flexWrap: `wrap`, justifyContent: `start`, m: -1 }}>
              {filterData.map(
                ({ Id, ModelResimLinki, Model3DResimLinki, IsAdi, User }, index) => (
                  <Box key={`kti-${Id}`} sx={styles.wrapper}>
                    <ContentContainer
                      content={{
                        variant: "cards.interactive",
                      }}
                      variant='cards.interactive'
                      sx={{
                        overflow: `hidden`,
                        height: `full`,
                        backgroundColor: "#E0E0E0",
                        borderStyle: "none"
                      }} >
                      <Flex as='article' sx={styles.artic}>
                        <>
                          <div className='cls-model-adi' onClick={async e => {
                            e.preventDefault();
                            setSeciliData(filterData[index])
                            setdetayModalShow(true);
                          }} >
                            <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                              <Box sx={styles.indirmeImageWrapper}>
                                {
                                  <div id="indirmeListSvg">
                                    <TransformWrapper  >
                                      <TransformComponent >
                                        <img src={window.location.href.includes('localhost') ? Model3DResimLinki : ModelResimLinki}
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                          }} />
                                      </TransformComponent>
                                    </TransformWrapper>
                                  </div>
                                }
                              </Box>
                              <Divider space={1} />

                            </Box>
                          </div>

                          <Flex
                            sx={{
                              alignItems: `center`,
                              justifyContent: `space-around`,

                            }}

                          >
                            <Text
                              key={`mdls${Id}`}
                              color="black"
                              as="span"
                            >
                              {IsAdi.length > 18 ? IsAdi.substring(0, 18) + ".." : IsAdi}
                            </Text>

                          </Flex></>
                      </Flex>
                    </ContentContainer>

                  </Box>
                )
              )}
            </Flex>
          )}
          {(
            filterData?.length == 0
            &&
            <div className='no-search'>
              <Divider space="2" />
              <img src={noSearchPng} alt="online kutu tasarımı" style={{ width: "200px", objectFit: "contain" }} />
              <div>Üzgünüz Aradığınız kriterler indirme geçmişinde bulunamadı</div>
              <div> <span onClick={e => {

                setFilterData(Data);
                setAramaMi(true);
                setSearchText("");

                e.preventDefault();
              }} className='search-tum'>Tüm İndirmelerime</span> gözatarak aradığınız çizimi bulabilirsiniz</div>

              <Divider space="5" />
            </div>
          )}

          <ReactModal
            id={"indirmeDetayModal"}
            isOpen={detayModalShow}
            onRequestClose={() => setdetayModalShow(false)}
            closeTimeoutMS={300}
            className='CustomModalPortalContent'
            overlayClassName='ModalPortalOverlay'
            shouldFocusAfterRender={false}
            sx={css({ ...styles.modalContent2 })}
            {...props}
          >
            {seciliData &&
              <form
                onSubmit={onSubmit}
                method='POST'
              >

                <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
                  <Box sx={styles.wrapper1}>
                    <Box
                      sx={{
                        display: [`none`, null, `block`],
                        flex: 1,
                        minWidth: [null, null, null, 400],
                        borderRadius: `left`,
                        overflow: `hidden`
                      }}
                    >
                      <Box sx={{ flex: 1, py: [1, 1], px: [2, 3], textAlign: "center" }}>
                        <Text
                          variant={"span"}
                          color="alpha"
                          mb='1'
                        >
                          {seciliData.IsAdi}
                        </Text>
                        <Text
                          sx={{ fontSize: "12px" }}
                          color="grey"
                          as="span"
                        >
                          {seciliData.User.length > 18 ? seciliData.User.substring(0, 18) + ".." : seciliData.User} {seciliData.Tarih}
                        </Text>

                        <div style={{ margin: "5px" }}>
                          {/* <Text
                            variant={"span"}
                            color="black"
                            mb='1'
                          >
                            {seciliData.IsAdi}
                          </Text> */}
                          <Box sx={styles.wrapper1}>

                            {seciliData.Gv.map(
                              ({ Level, IstenecekVeriler, MalzemeListesi, Baslik,
                              }, ind) => (
                                <Box key={`lblmdlmain${ind}`} sx={styles.wrapper1}>
                                  <ContentContainer
                                    content={{
                                      variant: "cards.interactive",
                                    }}
                                    variant='cards.interactive'
                                    sx={styles.card} >
                                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                                      <Text key={`lblbaslik${ind}`} as='span' color='alpha'>
                                        {Baslik}
                                      </Text>
                                    </Box>
                                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3], color: "black" }}>

                                      Malzeme:     {MalzemeListesi.filter((data) => {
                                        if (data.SeciliMi == true)
                                          return true;
                                      })[0].MalzemeAdi
                                      }


                                    </Box>
                                    {IstenecekVeriler.map(
                                      ({ Id, ModelId, GorunenAdi, FormulValue, HataMesaji, HataVarMi, Component, ComboList, BirimText, OlcuCizgiIdsi }, index) => (
                                        <Box key={`lblmlzvalid${Id}`} sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                                          {Component != undefined &&
                                            (Component == 0 ?
                                              <div  >
                                                <Flex
                                                  sx={{
                                                    alignItems: `start`,
                                                    justifyContent: `start`,
                                                    backgroundColor: '#E2E2E2'
                                                  }}  >
                                                  <Box sx={{ flex: 2.3, textAlign: "center" }}>
                                                    <Text key={`lblgrad${ind}${index}`} as='span' color='alpha' sx={{ fontSize: GorunenAdi.length > 9 ? 10 : 16, textAlign: "center" }}>
                                                      {GorunenAdi}
                                                    </Text>
                                                  </Box>
                                                  <Box sx={{ flex: 3, backgroundColor: '#edf2f7', color: "black" }}>
                                                    {FormulValue}
                                                  </Box>
                                                  <Box sx={{ flex: 1, px: 1 }}>
                                                    <Text key={`lblbrmtext${ind}`} as='span' color='alpha' sx={{ fontSize: BirimText || BirimText.length > 5 ? 10 : 15 }}>
                                                      {BirimText}
                                                    </Text>
                                                  </Box>
                                                </Flex>
                                              </div>
                                              :
                                              <div >
                                                <Flex
                                                  sx={{
                                                    alignItems: `start`,
                                                    justifyContent: `start`,
                                                    backgroundColor: '#E2E2E2'
                                                  }}  >
                                                  <Box sx={{ flex: 2.3, textAlign: "center" }}>
                                                    <Text key={`lblgrad${ind}${index}`} as='span' color='alpha' sx={{ fontSize: GorunenAdi.length > 9 ? 10 : 16, textAlign: "center" }}>
                                                      {GorunenAdi}
                                                    </Text>
                                                  </Box>
                                                  <Box sx={{ flex: 3, backgroundColor: '#edf2f7' }}>
                                                    {ComboList.filter((data) => {
                                                      if (data.SeciliMi == true)
                                                        return true;
                                                    })[0].GorunenAdi
                                                    }
                                                  </Box>
                                                  <Box sx={{ flex: 1, px: 1 }}>
                                                    <Text key={`lblbrmtext${ind}`} as='span' color='alpha' sx={{ fontSize: BirimText || BirimText.length > 5 ? 10 : 15 }}>
                                                      {BirimText}
                                                    </Text>
                                                  </Box>
                                                </Flex>
                                              </div>)
                                          }

                                        </Box>
                                      )
                                    )}
                                    <Divider space="2" />
                                  </ContentContainer>
                                </Box>
                              )
                            )}
                          </Box>
                          {seciliData.IcOlcuMu != null && (
                            <Box sx={{ flex: [`100%`, null, null, 1], minWidth: 400, }} >
                              {seciliData.IcOlcuMu == false ?
                                <>
                                  <Text color='red'>
                                    Bu Model Dış Ölçü Seçilerek Çizilmiştir. </Text> <Text color='red'>
                                    Kesim kalıbı ölçüsüdür. </Text></> :
                                <Text color='red'>
                                  Bu Model İç Ölçü Seçilerek Çizilmiştir</Text>
                              }
                            </Box>
                          )
                          }
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={styles.wrapper2}>
                    <Box sx={{ flex: 1, py: [10, 1], px: [2, 3], textAlign: "center" }}>
                      <Text key="fffsd" as='span' color='black'>
                        {seciliData.ModelAdi}
                      </Text>
                    </Box>
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      <Box sx={styles.detayimageWrapper}>
                        {
                          <div id="indirmePopupSvg">
                            <TransformWrapper style={{ width: "auto" }}>
                              <TransformComponent>
                                <img src={window.location.href.includes('localhost') ? seciliData.Model3DResimLinki : seciliData.ModelResimLinki}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }} />

                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        }
                      </Box>
                    </Box>
                    <Flex sx={{
                      flexWrap: `wrap`,
                      textAlign: "right",
                      marginBottom: "10px"

                    }}>
                      <Box sx={{ flex: 1, textAlign: "center" }} >
                        <div className='ind-icon-btn icon-red'  >
                          <IconButton
                            id={"btnIndSil"}
                            onClick={e => {
                              setbtnID("btnIndSil");
                              setsilmePopup(true);
                            }}
                            aria-label='Ekle'
                            autoFocus
                            size="large"
                            sx={{ margin: "10px", color: "red" }}
                          >  <AiOutlineDelete size={23} color="red" /> Sil
                          </IconButton>
                        </div>
                      </Box>
                      <Box sx={{ flex: 1, textAlign: "center" }} >
                        <div className='ind-icon-btn icon-blue'  >
                          <IconButton

                            id={"btnRevize"}
                            onClick={e => {
                              localStorage.setItem("pM", seciliData.MdlId);
                              window.location.assign("/bicak-izi-indir");
                            }}
                            aria-label='Ekle'
                            autoFocus
                            size="large"
                            sx={{ margin: "10px", color: "blue" }}
                          >  <AiOutlineCloudSync size={23} color="blue" /> Revize Et
                          </IconButton>
                        </div>
                      </Box>
                      <Box sx={{ flex: 1, textAlign: "center" }} >
                        <div className='ind-icon-btn icon-green'  >
                          <IconButton

                            id={"btnTekrarIndir"}
                            onClick={e => {
                              e.preventDefault();
                              document.getElementById("ifRam").removeAttribute("src");
                              document.getElementById("ifRam").setAttribute("src", seciliData.DoLink);

                            }}
                            aria-label='Ekle'
                            autoFocus
                            size="large"
                            sx={{ margin: "10px", color: "green" }}
                          >  <AiOutlineCloudDownload size={23} color="green" /> Tekrar İndir
                          </IconButton>
                        </div>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </form>
            }
          </ReactModal>
          <ReactModal
            id={"silmePopup"}
            isOpen={silmePopup}
            onRequestClose={() => setsilmePopup(false)}
            closeTimeoutMS={300}
            className='SilmeModalPortalContent'
            overlayClassName='ModalPortalOverlay'
            shouldFocusAfterRender={false}
            sx={css({ ...styles.modalContent2 })}
            {...props}
          >
            {seciliData &&
              <form
                onSubmit={onSubmit}
                method='POST'
              >
                <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
                  <Box sx={styles.wrapper2}>
                    <Box sx={{ flex: 1, py: [10, 1], px: [2, 3], textAlign: "center" }}>
                      <div className='dv-l'>
                        <Divider space="3" />
                        <img src={removeGif} alt="remove die cut box drawing popup" style={{ width: "100px", objectFit: "contain" }} />
                      </div>
                      <Text as='span' color='black'>
                        {seciliData.IsAdi} adlı işe ait çizimi arşivinizden silmek istediğinize emin misiniz?
                      </Text>
                      <Divider space="2" />
                    </Box>

                    <Flex sx={{
                      flexWrap: `wrap`,
                      textAlign: "right"
                    }}>

                      <Box sx={{ flex: 1, textAlign: "center" }} >
                        <div className='ind-icon-btn icon-red'  >
                          <IconButton

                            id={"btnSilVazgec"}
                            onClick={e => {
                              setbtnID("btnSilVazgec");
                              setsilmePopup(false);
                            }}
                            aria-label='Ekle'
                            autoFocus
                            size="large"
                            sx={{ margin: "10px", color: "red" }}
                          >  <AiOutlineArrowLeft size={23} color="red" /> Vazgeç
                          </IconButton>

                        </div>

                      </Box>

                      <Box sx={{ flex: 1, textAlign: "center" }} >
                        <div className='ind-icon-btn icon-green'  >
                          <IconButton
                            type="submit"
                            id={"btnSilEvet"}
                            onClick={e => {
                              setbtnID("btnSilEvet");
                            }}
                            aria-label='Ekle'
                            autoFocus
                            size="large"
                            sx={{ margin: "10px", color: "green" }}
                          >  <AiOutlineCheck size={23} color="green" /> Evet Sil
                          </IconButton>
                        </div>
                      </Box>
                    </Flex>
                  </Box>

                </Flex>

              </form>
            }
          </ReactModal>
        </div>}
      <div id="ifRa" style={{ display: "none", height: "0px" }}>
        <iframe id="ifRam" ></iframe>
      </div>
    </Container>
  )
}
export default WithDefaultContent(BlogBlock05)
